.bg {
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    min-width: 370px;
}

.wrapper {
    background-color: white;
    flex-basis: 1024px;
    max-width: 1024px;
    min-width: 370px;
    display: flex;
    justify-content: space-between;
    padding: 30px 50px 30px 50px;
    align-items: center;
}

.left_block {
    // background-color: white;
    display: flex;
    align-items: center;
    gap: 30px;
    padding-right: 10px;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.circle {
    background-color: #cdcdcd;
    width: 35px;
    height: 35px;
    border-radius: 100px;
  @media screen and (max-width: 450px){
    display: none;
  }
}



.my_cabinet {
    display: flex;
    gap: 10px;
    align-items: center;
    
}

.logout {
    cursor: pointer;
}

// настройки шрифтов
.cabinet {
    font-weight: 700;
}

.logoind {
    width: 70px;
}

.footer_cabinet_layout {
    min-width: 370px;
}
