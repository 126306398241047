#root, body, html{
  height: 100%;
}

.bg_quiz {
  background-color: #F8F8F8;
  display: flex;
  justify-content: center;
  min-width: 370px;
  height: 100%;
}

.wrapper_quiz {
  background-color: white;
  flex-basis: 1024px;
  max-width: 1024px;
  padding: 10px 50px 10px 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 80px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.quiz {
  background-color: white;
  width: 300px;
  padding: 25px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  border-radius: 12px;
  
  -webkit-box-shadow: 0px 0px 22px -8px rgba(34, 60, 80, 0.47);
  -moz-box-shadow: 0px 0px 22px -8px rgba(12, 100, 80, 0.47);
  box-shadow: 0px 0px 12px -8px rgba(12, 60, 80, 0.47);
}

.title_quiz {
  font-weight: 700;
  font-size: 25px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.reg {
  font-size: 19px;
}

.post_mail {
  color: #919191;
}

.button_quiz {
  background-color: green;
  color: white;
  border-radius: 12px;
  padding: 20px;
  font-weight: 700;
}

.error_quiz {
  color: tomato;
}

.input_quiz {
  border: 1px solid #00000021;
  border-radius: 12px;
  padding: 20px;
}


.dots_quiz{
  display: flex;
  justify-content: space-between;
}

.dot{
  background-color: grey;
  width: 20%;
  height: 3px;
  border-radius: 100px;
}

.dot_active{
  background-color: tomato;
  width: 20%;
  height: 3px;
  border-radius: 100px;
}







