#root, body, html{
  height: 100%;
}

.bg_form {
  background-color: #F8F8F8;
  display: flex;
  justify-content: center;
  height: 100%;
  min-width: 370px;
}

.wrapper_form {
  background-color: white;
  width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}


.form_register {
  background-color: white;
  flex-basic: 500px;
  width: 400px;
  padding: 25px;
  margin: 25px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  border-radius: 12px;
  
  -webkit-box-shadow: 0px 0px 22px -8px rgba(34, 60, 80, 0.47);
  -moz-box-shadow: 0px 0px 22px -8px rgba(12, 100, 80, 0.47);
  box-shadow: 0px 0px 12px -8px rgba(12, 60, 80, 0.47);
  
  @media screen and (max-width:451px) {
    width: 90%;
  }
}

.input {
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
}

.bottom_form {
  background-color: green;
  color: white;
  border-radius: 12px;
  padding: 20px;
  font-weight: 700;
}

.error_form {
  color: tomato;
}
