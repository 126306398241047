@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: black;
  background-color: white;
}
a {
  color: black;
}

.App {
  min-width:370px;
}


