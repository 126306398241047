.admin_menu li{list-style: none;}

.admin_header {
    position: relative;
    z-index: 10;
    width: 100%;
    min-width: 370px;
    display: flex;
    justify-content: center;
    color: black;
    background-color:azure;

    .admin_header_container {
        flex-basis: 1024px;
        min-width: 370px;
        background-color: azure;
        display: flex;
        align-items: center;
        padding: 30px 35px;
        column-gap: 20px;
        font-size: 14px;
        justify-content: space-between;

        > a {
            flex-shrink: 2;
            align-self: center;
           
        }
    }
}

.admin_menu {
    display: flex;
    flex-shrink: 1;
    width: 100%;
    line-height: 20px;
    justify-content: space-between;

}


.menu_cases{
    margin: 0 auto;
    max-width: 1024px;
    min-width: 370px;
    background-color: white;
    padding: 5px 20px;
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
    border-bottom: 1px solid #e9e9e9;
}

.logo img{
    width: 120px;
}
@media screen and (max-width:800px){
  .admin_menu{
    flex-direction:column;
    font-size: 16px;
    row-gap: 25px;
    padding: 50px 50px 50px 50px;
    background-color: #F4F4F4;
    position:absolute;
    z-index:-13;
    top: 140px;
    left: 0px;
    width: 100%;
  }
  .admin_menu li{
    border-bottom: 0.3px solid #DCDCDC;
    padding-bottom: 10px;
    }
}



.active {
  color: tomato;
  cursor: default;
}





